import { IdNamePair } from "types/id-pair.types";
import { SliderPair } from "types/slider.types";
import countriesFlagJson from "data/countries_flags.json";
import countriesGeoJson from "data/countries_geojson.json";
import continentsJson from "data/continents.json";
import validCountriesJson from "data/valid_countries.json";
import { theme } from "twin.macro";

export const countriesFlag = countriesFlagJson[0].data;
export const countriesCoords = countriesGeoJson[0].features;
export const continentsList = continentsJson[0].data;
const validCountryCodes = new Set(
  validCountriesJson.data.map((country) => country.ccode)
);

export const countriesList: any = countriesCoords
  .map((country) => {
    const matchingCountry = countriesFlag.find(
      (item) => item.iso3c === country.id
    );
    return matchingCountry && validCountryCodes.has(matchingCountry.iso3c)
      ? { ...country, ...matchingCountry }
      : null;
  })
  .filter((country) => country !== null);

export const actionsList: string[] = ["about_us", "methodology"];

export const handleCategoriesIcon = (code: string) => {
  switch (code) {
    case "01":
      return "assets/images/food_and_non_alcoholic.svg";
    case "02":
      return "assets/images/alcoholic_beverages_tobacco_and_narcotics.svg";
    case "03":
      return "assets/images/clothing_and_footwear.svg";
    case "04":
      return "assets/images/housing.svg";
    case "05":
      return "assets/images/furnishings_household.svg";
    case "06":
      return "assets/images/health.svg";
    case "07":
      return "assets/images/transport.svg";
    case "08":
      return "assets/images/communication.svg";
    case "09":
      return "assets/images/recreation_and_culture.svg";
    case "10":
      return "assets/images/education.svg";
    case "11":
      return "assets/images/restaurants_and_hotels.svg";
    case "12":
      return "assets/images/miscellaneous_goods_and_services.svg";
    default:
      break;
  }
};

export const legendList: { id: string; color: string; text: string }[] = [
  { id: "HIGH_SPENDING", color: theme`colors.green.400`,
    text: "#000000" },
  {
    id: "MEDIUM_HIGH_SPENDING",
    color: theme`colors.green.600`, text: "#000000"
  },
  { id: "MEDIUM_SPENDING", color: theme`colors.blue.100`, text: "#000000" },
  {
    id: "MEDIUM_LOW_SPENDING",
    color: theme`colors.blue.200`,
    text: "#ffffff",
  },
  { id: "LOW_SPENDING", color: theme`colors.blue.400`, text: "#ffffff" },
];

export const countryOverviewList: IdNamePair[] = [
  {
    name: "category_ranking",
    id: "category",
  },
  {
    name: "spending_ranking",
    id: "spending",
  },
  {
    name: "overview",
    id: "overview",
  },
];

export const countryNationalList: IdNamePair[] = [
  {
    name: "global",
    id: "GLOBAL",
  },
  {
    name: "national",
    id: "NATIONAL",
  },
  {
    name: "continental",
    id: "CONTINENTAL",
  },
];

export const links: IdNamePair[] = [
  {
    name: "home",
    id: "#home",
  },
  {
    name: "map_and_categories",
    id: "#map",
  },
  {
    name: "comparison",
    id: "#comparison",
  },
  {
    name: "about_us_and_methodology",
    id: "#aboutUs",
  },
  {
    name: "meet_world_data_pro",
    id: "#worldDatapro",
  },
];

export const meet_wdl_list: string[] = [
  "all_consumer_spending_macro_categories",
  "180_countries",
  "age_groups",
  "spending_groups",
  "through_to_2035",
];

export const socialMediaLinks: IdNamePair[] = [
  {
    name: "globe",
    id: "https://worlddata.pro",
  },
  {
    name: "linkedIn",
    id: "https://www.linkedin.com/company/world-data-lab",
  },
  {
    name: "twitter",
    id: "https://twitter.com/worlddatalab",
  },
  {
    name: "instagram",
    id: "https://instagram.com/worlddatalab",
  },
];

export const years: SliderPair[] = [
  {
    name: "two_thousand_twentyfour",
    label: "2024",
    value: 2024,
  },
  {
    name: "two_thousand_twentyfive",
    label: "2025",
    value: 2025,
  },
  {
    name: "two_thousand_twentysix",
    label: "2026",
    value: 2026,
  },
  {
    name: "two_thousand_twentyseven",
    label: "2027",
    value: 2027,
  },
  {
    name: "two_thousand_twentyeight",
    label: "2028",
    value: 2028,
  },
  {
    name: "two_thousand_twentynine",
    label: "2029",
    value: 2029,
  },
  {
    name: "two_thousand_thirty",
    label: "2030",
    value: 2030,
  },
  {
    name: "two_thousand_thirtyone",
    label: "2031",
    value: 2031,
  },
  {
    name: "two_thousand_thirtytwo",
    label: "2032",
    value: 2032,
  },
  {
    name: "two_thousand_thirtythree",
    label: "2033",
    value: 2033,
  },
  {
    name: "two_thousand_thirtyfour",
    label: "2034",
    value: 2034,
  },
];

export const categoriesTableHeads: IdNamePair[] = [
  {
    name: "$_spent_with_housing",
    id: "housing",
    code: "04",
  },
  {
    name: "$_spent_with_communication",
    id: "communication",
    code: "08",
  },
  {
    name: "$_spent_with_furnishings_household",
    id: "furnishings",
    code: "05",
  },
  {
    name: "$_spent_with_food_and_non_alcoholic_beverages",
    id: "food",
    code: "01",
  },
  {
    name: "$_spent_with_restaurants_and_hotels",
    id: "restaurants",
    code: "11",
  },
  {
    name: "$_spent_with_clothing_and_footwear",
    id: "clothing",
    code: "03",
  },
  {
    name: "$_spent_with_transport",
    id: "transport",
    code: "07",
  },
  {
    name: "$_spent_with_health",
    id: "health",
    code: "06",
  },
  {
    name: "$_spent_with_recreation_and_culture",
    id: "recreation",
    code: "09",
  },
  {
    name: "$_spent_with_education",
    id: "education",
    code: "10",
  },
];

export const measuresList: IdNamePair[] = [
  { name: "total_spending", id: "TOTAL_SPENDING" },
  { name: "person_spending", id: "PER_PERSON" },
];

export const categoriesList: IdNamePair[] = [
  { name: "all_categories", id: "all_categories", code: "01" },
  {
    name: "alcoholic",
    id: "alcoholic",
    code: "02",
  },
  { name: "clothing", id: "clothing", code: "03" },
  { name: "communication", id: "communication", code: "08" },
  { name: "education", id: "education", code: "10" },
  { name: "furnishings", id: "furnishings", code: "05" },
  { name: "food", id: "food", code: "01" },
  { name: "health", id: "health", code: "06" },
  { name: "housing", id: "housing", code: "04" },
  {
    name: "miscellaneous",
    id: "miscellaneous",
    code: "12",
  },
  { name: "recreation", id: "recreation", code: "09" },
  { name: "restaurants", id: "restaurants", code: "11" },
  { name: "transport", id: "transport", code: "07" },
];

export const mapCategoriesLists: IdNamePair[] = [
  { name: "housing", id: "housing" },
  {
    name: "miscellaneous",
    id: "miscellaneous",
  },
  { name: "education", id: "education" },
  { name: "recreation", id: "recreationCulture" },
  { name: "health", id: "health" },
  { name: "clothing", id: "clothingFootwear" },
  { name: "communication", id: "communication" },
  { name: "restaurants", id: "restaurantsHotels" },
  { name: "furnishings", id: "furnishingsHousehold" },
  { name: "transport", id: "transport" },
  {
    name: "alcoholic",
    id: "alcoholicBeverages",
  },
  { name: "food", id: "foodNonAlcoholic" },
];
